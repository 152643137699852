import API from './Client.js'

// API interface for loading the user's profile
export const registerAttendee = async (data) => {
  try {
    const response = await API.post('attendees/register', data)
    // console.log('Full response:', response) // Debug-Ausgabe
    return response.data
  } catch (err) {
    // console.error('Error:', err) // Fehlerprotokollierung
    throw err.response ? err.response.data : err // Anpassung für Fehler ohne `response`
  }
}

// API interface for updating the attendee
export const updateAttendee = async (data) => {
  try {
    const { first_name, middle_name, last_name } = data

    const response = await API.put(
      `attendees/${first_name}/${middle_name}/${last_name}`,
      data
    )
    return response.data
  } catch (err) {
    throw err.response ? err.response.data : err
  }
}

// API interface for deleting the attendee
export const deleteAttendee = async (data) => {
  try {
    const { first_name, middle_name, last_name } = data

    const response = await API.delete(
      `attendees/${first_name}/${middle_name}/${last_name}`
    )
    return response.data
  } catch (err) {
    throw err.response ? err.response.data : err
  }
}

// API interface for fetching all attendees
export const fetchAttendees = async () => {
  try {
    const response = await API.get('attendees/')
    console.log('apis/attendee data:', response.data) // Nur response.data ausgeben
    return response.data
  } catch (err) {
    throw err.response ? err.response.data : err
  }
}
