import React from 'react'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const EventPrivateRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)

  return isAuthenticated ? children : children // Bleibt auf der EventLogin-Seite
}

export default EventPrivateRoute
