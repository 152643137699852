import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AutoComplete, Input, Layout, Typography, Button } from 'antd'
import {
  fetchAttendeesForEventAction,
  updateAttendeesForEventAction,
} from '../../store/attendeeEvents/attendeeEvents.actions.js'
import { useNavigate, useLocation } from 'react-router-dom'

const { Content } = Layout
const { Title } = Typography
const { Search } = Input

const EventLogin = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const attendees = useSelector((state) => state.attendeeEvents.eventAttendees)
  console.log(attendees)

  const [attendeeOptions, setAttendeeOptions] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [selectedAttendee, setSelectedAttendee] = useState(null)

  useEffect(() => {
    const eventID = location.pathname.split('/')[2]

    if (eventID) {
      dispatch(fetchAttendeesForEventAction(eventID))
    } else {
      navigate('/error')
    }
  }, [dispatch, location, navigate])

  useEffect(() => {
    setAttendeeOptions(generateAttendeeOptions(attendees, searchValue))
  }, [attendees, searchValue])

  const generateAttendeeOptions = (attendees, searchValue) => {
    return attendees
      .filter(
        (attendee) =>
          attendee.first_name
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          (attendee.middle_name &&
            attendee.middle_name
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          attendee.last_name.toLowerCase().includes(searchValue.toLowerCase())
      )
      .map((attendee) => ({
        value: `${attendee.first_name} ${
          attendee.middle_name ? attendee.middle_name + ' ' : ''
        }${attendee.last_name}`,
        label: `${attendee.first_name} ${
          attendee.middle_name ? attendee.middle_name + ' ' : ''
        }${attendee.last_name}`,
        attendeeId: attendee.attendee_id,
        disabled: attendee.times_attended > 0, // Disable option if attendee has already attended
      }))
  }

  const onAttendeeSearch = (value) => {
    setSearchValue(value)
    setDropdownVisible(true)
  }

  const handleAttendeeSelect = (value, option) => {
    setSelectedAttendee(option)
    setDropdownVisible(false)
  }

  const handleLogin = () => {
    if (selectedAttendee) {
      const eventID = location.pathname.split('/')[2]
      dispatch(
        updateAttendeesForEventAction({
          attendeeId: selectedAttendee.attendeeId,
          eventID,
          incrementBy: 1,
        })
      )
      navigate(`/event/${eventID}/success`)
    }
  }

  const styles = {
    content: {
      backgroundColor: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '75vh',
    },
    container: {
      backgroundColor: 'transparent',
      padding: '40px',
      maxWidth: '400px',
      width: '100%',
      marginTop: '-100px',
    },
    title: {
      textAlign: 'center',
      marginBottom: '20px',
      color: '#D51067',
      fontWeight: 'bold',
    },
    form: {
      width: '100%',
    },
    button: {
      width: '100%',
    },
    error: {
      color: 'red',
      marginBottom: '10px',
      textAlign: 'center',
    },
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content style={styles.content}>
        <div style={styles.container}>
          <Title style={styles.title}>Event Login</Title>
          <AutoComplete
            options={attendeeOptions}
            onSearch={onAttendeeSearch}
            onSelect={handleAttendeeSelect}
            open={dropdownVisible}
            onFocus={() => setDropdownVisible(true)}
            onBlur={() => setDropdownVisible(false)}
            style={{ width: '100%' }}
          ></AutoComplete>
         <Button
            type="primary"
            onClick={handleLogin}
            style={{ 
              ...styles.button, 
              marginTop: '1rem',
              fontSize: '16px' // Schriftgröße auf mindestens 16px setzen
            }}
            disabled={!selectedAttendee}
          >
            Registrieren
          </Button>
        </div>
      </Content>
    </Layout>
  )
}

export default EventLogin
