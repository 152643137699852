import React, { useEffect, useMemo, useState } from 'react'
import { Particles, initParticlesEngine } from '@tsparticles/react' // Korrigierter Import
import { loadSlim } from '@tsparticles/slim'

const EventSuccess = () => {
  const [init, setInit] = useState(false)

  useEffect(() => {
    // Initialisierung der Partikel-Engine
    initParticlesEngine(async (engine) => {
      await loadSlim(engine)
    }).then(() => {
      setInit(true)
    })
  }, [])

  const particleOptions = useMemo(
    () => ({
      background: {
        color: { value: '#ffffff' },
      },
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: 'push',
          },
          onHover: {
            enable: true,
            mode: 'repulse',
          },
        },
        modes: {
          push: {
            quantity: 4,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
        },
      },
      particles: {
        color: {
          value: '#D51067',
        },
        links: {
          color: '#D51067',
          distance: 150,
          enable: true,
          opacity: 0.5,
          width: 1,
        },
        move: {
          direction: 'none',
          enable: true,
          outModes: {
            default: 'bounce',
          },
          random: false,
          speed: 2,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 80,
        },
        opacity: {
          value: 0.5,
        },
        shape: {
          type: 'circle',
        },
        size: {
          value: { min: 1, max: 5 },
        },
      },
      detectRetina: true,
    }),
    []
  )

  return (
    <div style={{ position: 'relative', height: '100vh', width: '100%' }}>
      {init && (
        <Particles
          id="tsparticles"
          options={particleOptions}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 0, // Sicherstellen, dass Partikel im Hintergrund bleiben
          }}
        />
      )}
      <div
        style={{
          position: 'relative',
          zIndex: 1,
          textAlign: 'center',
          marginTop: '20vh',
        }}
      >
      <h3 style={{
          color: '#D51067',
          marginTop: '250px',        // Angepasster Abstand nach unten
          maxWidth: '300px',        // Begrenzte Breite für Schmalheit
          marginLeft: 'auto',       // Zentriert das Element horizontal
          marginRight: 'auto',
          textAlign: 'center',      // Zentriert den Text innerhalb des Elements
          fontSize: '25px',         // Mittlere Schriftgröße zwischen h2 und h3
        }}>
          Du wurdest erfolgreich registriert. Viel Spaß!
        </h3>
      </div>
    </div>
  )
}

export default EventSuccess
