import React from 'react'
import { Layout } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import './Header.css' // Importiere die CSS-Datei

const { Header } = Layout

const AppHeader = () => {
  const navigate = useNavigate() // useNavigate-Hook für die Navigation
  const location = useLocation() // useLocation-Hook für den aktuellen Pfad

  const handleLogoClick = () => {
    navigate('/home') // Weiterleitung zur /home-Route
  }

  const handleStatisticsClick = () => {
    navigate('/statisticsTable') // Weiterleitung zur /statistics-Route
  }

  // Bedingungen für das Ausblenden des Statistik-Reiters
  const hideStatistics = [
    '/event/', // Matches Pfade wie /event/:id
    '/eventHome/', // Matches Pfade wie /eventHome/:id
    '/event/:id/success', // Pfad für das Erfolgs-Event
    '/login',
    '/eventLogin',
  ].some((path) => location.pathname.startsWith(path))

  return (
    <Layout>
      <Header className="header">
        <div className="logo" onClick={handleLogoClick}>
          Rotaract Distrikt 1842
        </div>
        {/* Statistik-Link nur anzeigen, wenn die Bedingungen nicht zutreffen */}
        {!hideStatistics && (
          <div className="link" onClick={handleStatisticsClick}>
            Statistik
          </div>
        )}
      </Header>
    </Layout>
  )
}

export default AppHeader
