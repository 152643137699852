import axios from 'axios';

// Wähle die Base URL basierend auf der NODE_ENV-Umgebung
let API_BASE_URL;

console.log('Aktuelle Umgebung (NODE_ENV):', process.env.NODE_ENV);

if (process.env.NODE_ENV === 'production') {
  API_BASE_URL = 'https://www.rotaract-district-1866.de/api'; // Produktions-URL
} else if (process.env.NODE_ENV === 'development') {
  API_BASE_URL = 'http://localhost:3001/api'; // Staging-URL (optional)
} else {
  API_BASE_URL = 'http://localhost:3001/api'; // Entwicklungs-URL
}

// Debugging: Prüfe, ob die korrekte API Base URL ausgewählt wurde
console.log('Aktuelle API Base URL:', API_BASE_URL);

// Erstelle eine Axios-Instanz mit der Basis-URL
const API = axios.create({
  baseURL: API_BASE_URL,
});

export default API;
